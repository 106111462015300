.img-closeup {
  overflow: hidden;
  border-radius: 2rem 0;
  img {
    transition: .4s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

body {
  background: url(../images/charte/bg-home.jpg);
}

.padding-cards {
    padding:2rem;

  @media(min-width: $screen-md-min) {
    padding: 0;
  }
}

.overflow-section {
  overflow: hidden;

}

.ta {

	padding: 0 0 25px 0;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 26rem;
		height: 2px;
    background-color: white;	
	}
}


.ta-inverse {

	padding: 0 0 25px 0;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 26rem;
		height: 2px;
    background-color: black;	
	}
}

.ta-dark {

	padding: 0 0 25px 0;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 26rem;
		height: 2px;
    background-color: black;	
	}
}

.container-video {

  @media(min-width: $screen-md-min) {

    width: 100%;
    height: calc(100vh - 10rem);
    overflow: hidden;
  }
}

.object-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.default-section {

  @media(min-width: $screen-md-min) {
    padding: 5rem;
  }
}

.icon-home {
  filter: invert(100%);
}

.text-shadow {
  text-shadow: 1px 1px 1px black;
  }

.bg-black {
  background-color: #000;
}

.border-black {
  border: 1px solid gray;
}

.card-dark {
  background-color: rgba(rgb(0, 0, 0), .7);
} 

.card-light {
  background-color: rgba(rgb(255, 255, 255), .8);
} 


.fig-caption-card {

  @media(min-width: $screen-md-min) {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    right: 3rem;
    padding: 3rem;
  }
  color: white;
  padding: 2rem;
  background-color: rgba(rgb(0, 0, 0), .5);
} 

//contact button

.contact-btn {
  position: fixed;
  top: 20rem;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 1000;
  padding: 20px 10px;
  background-color: $brand-second;
  border-radius: 0 0.5rem 0.5rem  0;
  color: rgb(223, 220, 220);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  &:hover {
    color: white;
  }
}